import { Link, graphql } from "gatsby"
import React from "react"
interface Props {
  id: number
  title: string
  eventDate: Date
  type: string
  location: string
  logo?: string
  className?: string
  internalLink?: string
  data: any
}

const Event = ({
  title,
  eventDate,
  type,
  location,
  logo,
  internalLink,
  className,
}: Props) => {
  return (
    <Link className={`event ${className ? className : ""}`} to={internalLink!}>
      <div className="date-block">
        <h3>{eventDate.toDateString()}</h3>
      </div>
      <div className="main-body">
        <h3 className="type">{type}</h3>
        <h2>{title}</h2>

        <p className="location">{location}</p>
        {logo && (
          <div className="logo-div">
            <img src={logo} alt="" className="img-fluid" />
          </div>
        )}
      </div>
    </Link>
  )
}

export default Event
