import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Event from "../components/event"
import { eventData } from "../_mockdata/eventsData"
import { graphql } from "gatsby"

interface Event {
  id: number
  title: string
  eventDate: Date
  type: string
  location: string
  logo?: string
  externalLink?: string
  ctaInternalLink?: string
  internalLink?: string
}

const EventsPage = ({ data }: any) => {
  const [upcomingEvents, setUpcomingEvents] = useState<Array<Event>>([])
  const [pastEvents, setPastEvents] = useState<Array<Event>>([])
  console.log(data)
  const currentDate = new Date()

  useEffect(() => {
    let upcoming = []
    let past = []
    for (let i = 0; i < eventData.length; i++) {
      if (eventData[i].eventDate.getTime() < currentDate.getTime()) {
        past.push(eventData[i])
      } else {
        upcoming.push(eventData[i])
      }
    }
    setUpcomingEvents(upcoming)
    setPastEvents(past)
  }, [])

  return (
    <Layout>
      <SEO title="Upcoming Events" description="" lang="en" meta={[]} />
      {/* Events Section */}
      <section className="events-section">
        <div className="container dark">
          <div className="row">
            <div className="col-lg-4 border-box">
              <h2>Events</h2>
            </div>

            <div className="col-lg">
              <h3>Upcoming</h3>
              {upcomingEvents?.map(event => (
                <Event
                  key={event.id}
                  internalLink={event.internalLink}
                  id={event.id}
                  title={event.title}
                  eventDate={event.eventDate}
                  location={event.location}
                  type={event.type}
                  logo={event.logo}
                />
              ))}

              <h3 className="mt-5">Previous</h3>
              {pastEvents
                ?.sort((a, b) => b.eventDate - a.eventDate)
                .map(event => (
                  <Event
                    className="previous"
                    key={event.id}
                    internalLink={event.internalLink}
                    id={event.id}
                    title={event.title}
                    logo={event.logo}
                    eventDate={event.eventDate}
                    location={event.location}
                    type={event.type}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allPrismicEvent {
      nodes {
        data {
          event_title {
            text
          }
          event_date
          event_category
          location
        }
      }
    }
  }
`

export default EventsPage
